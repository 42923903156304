import React from "react";
import "./HeroSection.css"; // Import the CSS file for styling
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const HeroSection = ({ onExploreClick }: { onExploreClick: () => void }) => {
  const nav = useNavigate();
  return (
    <div className="hero-section">
      {/* Background Image */}
      <div className="hero-image"></div>

      {/* Text Overlay */}
      <div className="hero-text">
        <Typography variant="h4" mt={5}>
          Welcome to Banvego Healthcare Solutions
        </Typography>
        <p>
          Providing quality Environmental, Educational, and Medical Delivery
          services in Central Florida.
        </p>
        <p style={{ fontSize: "0.9rem" }}>
          Service Areas: Sanford, Lake Mary, Longwood, Oviedo, Winter spring,
          Altermonte, Cassebery, Apopka, Orlando
        </p>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: 5,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
            onClick={onExploreClick}
          >
            Explore Our Services
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => nav("/contact")}
          >
            Schedule Consultation
          </Button>
          <Button
            variant="contained"
            color="info"
            sx={{ mt: 2 }}
            onClick={() => nav("/payment")}
          >
            Make Payment
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default HeroSection;
