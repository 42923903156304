import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const Payment = () => {
  useEffect(() => {
    // Short delay before redirect to show the loading state
    const redirectTimer = setTimeout(() => {
      window.location.href = "https://www.paypal.com/ncp/payment/JPQLUDLASYR6Y";
    }, 1500);

    return () => clearTimeout(redirectTimer);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "60vh",
        padding: 4,
        textAlign: "center",
      }}
    >
      <CircularProgress size={60} sx={{ mb: 3 }} />
      <Typography variant="h5" gutterBottom>
        Redirecting to Payment Portal
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Please wait while we redirect you to our secure payment page...
      </Typography>
    </Box>
  );
};

export default Payment;
