import React, { useRef } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Container,
} from "@mui/material";
import "./Services.css"; // Import the CSS file for styling
import { useNavigate, useParams } from "react-router-dom";

export const servicesData = [
  {
    id: "1",
    title: "Environmental Services",
    items: [
      "Facilities cleaning for private residents,nursing home, new building for occupants and sewage management",
      "Sanitization and  disinfection of healthcare facilities and offices",
    ],
    imageUrl: require("../../images/cleaning_1.jpg"),
  },
  {
    id: "2",
    title: "Medical Transportation",
    items: [
      "Dependable non-emergency medical transportation services such as doctor appointment, wheelchair, stretcher,discharge patient, pharmacy pickup,and Laboratory specimen’s",
      "Transportation of specialized patient for dialysis treatment",
      "We ensure comfort,safety and punctuality for patients and clients",
    ],
    imageUrl: require("../../images/delivery.jpg"),
  },
  {
    id: "3",
    title: "Community Health Education and Training services",
    items: [
      "Short -term online courses for caregivers and administrative staff. The list of courses includes: CPR,BLS, EPA,OSHA and competence skills. and quality improvement program",
    ],
    imageUrl: require("../../images/training.webp"),
  },

  // Add more service data as needed
];

export const ServiceDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return null;
  const service = servicesData.find((x) => x.id === id);
  if (!service) return null;
  return (
    <Container>
      <Box
        sx={{
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          color="secondary"
          sx={{ textAlign: "center", mt: 5 }}
        >
          {service.title}
        </Typography>
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
            m: 2,
          }}
        >
          <img
            src={service.imageUrl}
            alt={service.title}
            style={{ width: "100%" }}
          />
        </Box>
        <Typography sx={{ mb: 3, color: "#333" }}>
          <ul>
            {service.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/contact");
          }}
          sx={{
            mb: 3,
          }}
        >
          Schedule Consultation
        </Button>
      </Box>
    </Container>
  );
};
const Services = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Typography
        gutterBottom
        variant="h4"
        component="div"
        color="secondary"
        sx={{ textAlign: "center", m: 5 }}
      >
        Our Services
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          margin: 1,
          flexWrap: "wrap",
        }}
      >
        {servicesData.map((service) => (
          <Card
            key={service.id}
            sx={{
              width: { xs: "100%", lg: "25%", md: "30%" },
              transition: "transform 0.3s",
              margin: 1,
            }}
          >
            <CardActionArea onClick={() => navigate(`/services/${service.id}`)}>
              <CardMedia
                component="img"
                alt={service.title}
                height="240"
                image={service.imageUrl}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {service.title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: "#333" }}>
                  <ul>
                    {service.items.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </Typography>
                <Typography variant="button" color="primary" sx={{ mt: 5 }}>
                  LEARN MORE
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Services;
