import { Box } from "@mui/system";
import { Button, Container, TextField, Typography } from "@mui/material";
import React, { useRef } from "react";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import EmailIcon from "@mui/icons-material/Email";
import HeroSection from "./HeroSection";
import Services from "../Services/Services";
import AboutUs from "../About/AboutUs";
import CustomerReviews from "./CustomerReviews";

const Home = () => {
  //swal alert
  const sectionRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box sx={{ p: 0, mb: 2, alignItems: "center" }}>
      <HeroSection
        onExploreClick={() => {
          if (sectionRef.current) {
            sectionRef.current.scrollIntoView({
              behavior: "smooth",
              inline: "end",
            });
          }
        }}
      />
      <div ref={sectionRef}>
        <Services />
      </div>
      <Box sx={{ background: "#ddd" }}>
        <AboutUs />
      </Box>
      {/* <CustomerReviews /> */}
    </Box>
  );
};

export default Home;
